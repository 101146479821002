/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import useInput from '../../hooks/useInput';
import Input from './Input';
import './Form.scss';
import encode from '../../utils/encode';

const Form = React.memo(({
  color = "black",
  pathname,
  displayNumber = "(616) 805-4816",
}) => {
  const { value: name, bind: bindName } = useInput('');
  const { value: email, bind: bindEmail } = useInput('');

  const [file, setFile] = useState('');
  const [fileUrl, setFileUrl] = useState()
  const [progress, setProgress] = useState(null);
  const [message, setMessage] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setMessage(null);
  }

  const handleFirebaseUpload = (event) => {
    event.preventDefault();
    console.log('starting upload');
    //async upload function
    if(file === '') {
      console.error(`the image file is undefined`);
    }
    const uploadTask = firebase.storage().ref(`/uploads/${file.name}`)
      .put(file);
    // initiates firebase uploading
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      //takes a snapshot of the process as it's happening
      // console.log(snapshot);
      const uploadProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      console.log(uploadProgress);
      setProgress(uploadProgress);
    }, (err) => {
      // catches the errors
      console.log(err);
    }, () => {
      // gets the functions from storage, references the file storage in firebase by the children
      // gets the download url and then sets the file from firebase as the value for the imgUrl key
      firebase.storage().ref('uploads').child(file.name).getDownloadURL()
        .then(firebaseUrl => {
          setFileUrl(prevObject => (firebaseUrl))
          handleNotification(firebaseUrl);
        })
    });
  }

  const handleNotification = (url) => {
    console.log(encode({
      "form-name": "upload-form",
      "name": name,
      "email": email,
      "fileUrl": url,
    }));

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "mw-upload-submissions",
        "name": name,
        "email": email,
        "fileUrl": url,
      })
    })
      .then(() => {
        setMessage("Upload Complete!");
        setProgress('');
        setFile('');
        setFileUrl('');
      })
      .catch(error => {
        setFile('');
        setFileUrl('');
        setProgress('');
        setMessage(`Upload Error!`);
        console.log(error)
      });
  };

  return (
    <form
      id="upload-form"
      onSubmit={(event) => handleFirebaseUpload(event)}
      className="mw__contact-form"
      action="/#upload-form"
      name="mw-upload-submissions"
      data-netlify="true"
      method="post"
    >
      <input type="hidden" name="form-name" value="mw-upload-submissions" />
      <Input
        type="text"
        label="Company Name"
        name="name"
        id="company-name"
        color={color}
        {...bindName}
        required
      />
      <Input
        type="email"
        label="Email Address"
        name="email"
        id="contact-email"
        {...bindEmail}
        color={color}
        required
      />
      <div className={`mw__button--container-left`}>
        <input
          type="file"
          className="mw__form--file-input"
          name="file"
          onChange={handleFileChange}
          disabled={progress}
          required
          id="file"
        />
        <input type="hidden" name="fileUrl" label="File Download URL" value={fileUrl} />
        <label
          htmlFor="file"
          className={`mw__button mw__form--file-input-visible${file ? ' selected' : ''}`}
        >
          {file ? `...${file.name.slice(file.name.length - 15)}` : '+ Select File'}
        </label>
        <button
          className={`mw__button mw__form--submit${file ? ' ready' : ''}`}
          type="submit"
          disabled={message}
        >
          {message
            ? message
            : (
               progress ? `Uploading... ${progress}%` : 'Upload'
              )
            }
        </button>

      </div>
    </form>
  );
});

export default Form;
