import React from "react";
import { Grid, Cell } from '@trbl/react-css-grid';
import Header from '../components/Header';
import SEO from "../components/seo";
import Form from '../components/Form';
import "../scss/resets.scss";
import '../scss/app.scss';

const IndexPage = ({ location: { pathname }}) => (
  <>
    <SEO title="Home" />
    <div className="mw__app">
      <Header />
      <Grid
        className="mw__content"
      >
        <Cell
          hStart={2}
          hSpan={12}
        >
          <div>
            <h1>Upload Your Artwork to MessageWrap®</h1>
            <p>Use the form below to upload your art files. If you encounter any issues, please refresh and try again or call us at (616) 805-4816.</p>
          </div>
        </Cell>
          <Cell
            hStart={2}
            hSpan={12}          
          >
            <Form pathname={pathname} />
          </Cell>
      </Grid>
    </div>  
  </>
);

export default IndexPage
